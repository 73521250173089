// src/App.js
import React from 'react';
import Loading from './Loading';
import logo from './QLaunch-Logo1.jpg';

const App = () => {
  return (
    <div>
      <header className="App-header" style={{ backgroundColor: 'BLACK'}}>
        <a href="https://x.com/QLAUNCH_qubic" target="_blank" rel="noopener noreferrer">
          <img src={logo} className="App-logo" alt="logo" style={{ maxHeight: '10vh'}}/>
        </a>
      </header>
      <div className="app-container">
        <Loading />
      </div>
    </div>
  );
};

export default App;