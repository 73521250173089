// src/Loading.js
import React from 'react';
import './Loading.css';

const Loading = () => {
    return (
      <div className="wrap-text">
        <span className="loading-containerQ">Q</span>
        <span className="loading-containerQLaunch">oming Soon...</span>
        <span className="cursor">&nbsp;</span>
      </div>
    );
  };

export default Loading;
